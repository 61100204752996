import storeService from './storeService';
import axios from 'axios';
import actions from '../store/actions/actionTypes';
import general from "../store/actions/index";

const url = process.env.REACT_APP_BACKEND!;

class DataService {

    async loadSections() {

        //console.log('Loading section list');
        //const sectionData = await axios.get('http://185.127.150.35:5205/api/test/2')
        //sectionData.data = [{id:1,name:'Регион'},{id:2,name:'Мероприятия'},{id:3,name:'Портрет участника'}]

        const sectionData = await axios.get(url + 'modes',
            { params: {code : storeService.getStore().getState().general.callCode}});
        storeService.getStore().dispatch({
            type: actions.general.SET_SECTION_DATA,
            payload: sectionData.data,
        });
    }

    async loadParams(section_id : number) {

        //console.log('Loading current param list')
        const paramsData = await axios.get(url + 'indices',
            {
                params: {
                mode: section_id,
                code : storeService.getStore().getState().general.callCode
                    }
        });

        //console.log({ paramsData: paramsData.data });
        storeService.getStore().dispatch({
            type: actions.general.SET_PARAM_DATA,
            payload: paramsData.data,
        });
    }


    async loadStatRegions(param_id : number) {

        storeService.getStore().dispatch({
            type: actions.general.SET_IS_LOADING_ALL_DATA,
            payload: true,
        });

        //console.log('Loading parameter for all regions');
        const statisticData = await axios.get(url + 'index/' + param_id.toString(),
            { params: {code : storeService.getStore().getState().general.callCode}});

        storeService.getStore().dispatch({
            type: actions.general.SET_PARAM_ID,
            payload: param_id,
        });

        storeService.getStore().dispatch({
            type: actions.general.LOAD_STATISTIC_DATA,
            payload: statisticData.data,
        });

        storeService.getStore().dispatch({
            type: actions.general.SET_IS_LOADING_ALL_DATA,
            payload: false,
        });
    }

    async loadRegionInfo(region_id : number) {
        //console.log('Loading parameters for region');
        const regionData = await axios.get(url + 'regiondata',
            {
                params: {
                    region_id: region_id,
                    code : storeService.getStore().getState().general.callCode
                }});

        storeService.getStore().dispatch({
            type: actions.general.LOAD_REGION_DATA,
            payload: regionData.data,
        });

    }

    async loadRadar(region_id : number) {
        //console.log('Loading radar data');
        const radarData = await axios.get(url + 'radar',
            {params: {region_id: region_id,
                    code : storeService.getStore().getState().general.callCode}});
        const radarData0 = await axios.get(url + 'radar',
            {params: {region_id: 0,
                    code : storeService.getStore().getState().general.callCode}});

        storeService.getStore().dispatch({
            type: actions.general.LOAD_RADAR_DATA,
            payload: [radarData.data,radarData0.data ],
        });

    }

    async loadRecommendations(region_id : number) {
        //console.log('Loading recommendations data');
        const recomData = await axios.get(url + 'recommendations',
            {params: {region_id: region_id,
                    code : storeService.getStore().getState().general.callCode}});

        storeService.getStore().dispatch({
            type: actions.general.LOAD_RECOMMENDATIONS_DATA,
            payload: [recomData.data],
        });

    }

    async loadRadarSecond(region_id : number) {
        //console.log('Loading second radar data');
        const secondRadarData = await axios.get(url + 'radar_fadm',
            { params: {region_id: region_id,
                    code : storeService.getStore().getState().general.callCode}});
        const secondRadarData0 = await axios.get(url + 'radar_fadm',
            { params: {region_id: 0,
                    code : storeService.getStore().getState().general.callCode}});

        storeService.getStore().dispatch({
            type: actions.general.LOAD_SECOND_RADAR_DATA,
            payload: [secondRadarData.data,secondRadarData0.data],
        });

    }



    async preloadAllData() {

        //console.log('setting default');
        if (storeService.getStore().getState().general.callCode === '')
            return;

        await this.loadSections();
        const sec_id = storeService.getStore().getState().general.sectionData[0].id;
        general.general.setSectionId(sec_id);
        await this.loadParams(sec_id);

        const par_id = storeService.getStore().getState().general.paramData[0].indices[0].id;
        general.general.setParamId(par_id);
        await this.loadStatRegions(par_id);
    }


    async setCallCode(call_code: string) {
         axios.get(url + 'radar_fadm',
            { params: {region_id: 0, code: call_code }})
            .then(response => {
               storeService.getStore().dispatch({
                    type: actions.general.SET_CALL_CODE,
                    payload: call_code,
                });
            }, reason =>
                {
                    storeService.getStore().dispatch({
                        type: actions.general.SET_CALL_CODE,
                        payload: '',
                    });
                });

    }


}

const dataService = new DataService();

export default dataService;
