import React from 'react';
import MapLegendGradient from './MapLegendGradient';

interface IMapFooterProps {
    colorGradient : any[];
    range_max : number;
    range_min : number;
}

function MapFooter(props: IMapFooterProps) {
    return (
        <div className="MapFooter">
            <div className="MapFooter__items">
                <div className="MapFooter__item MapFooter__item_right">
                    <MapLegendGradient colorGradient={props.colorGradient} range_min={props.range_min} range_max={props.range_max}/>

                </div>
            </div>
        </div>
    );
}

export default MapFooter;
