import actions from '../actions/actionTypes';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';

export const initialState: GeneralState = {
    sectionId: 0,
    sectionData: [],
    paramId : 0,
    paramData: [],
    statisticData : [],
    regionId : 0,
    regionName : '',
    regionData : [],
    radarData : [],
    secondRadarData : [],
    recomData : [],
    isLoadingAllData: false,
    isMeropShowed : false,
    callCode : '',
    needReload : false
};

export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {

        case actions.general.SET_SECTION_ID: {
            let currentState = Object.assign({}, state);
            currentState.sectionId = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_IS_LOADING_ALL_DATA: {
            let currentState = Object.assign({}, state);
            currentState.isLoadingAllData = action.payload;
            currentState.needReload = false;
            return updateObject(state, currentState);
        }

        case actions.general.SET_SECTION_DATA: {
            let currentState = Object.assign({}, state);
            currentState.sectionData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_PARAM_ID: {
            let currentState = Object.assign({}, state);
            currentState.paramId = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_PARAM_DATA: {
            let currentState = Object.assign({}, state);
            currentState.paramData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_REGION_ID: {
            let currentState = Object.assign({}, state);
            currentState.regionId = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_REGION_NAME: {
            let currentState = Object.assign({}, state);
            currentState.regionName = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_MEROP_SHOWED: {
            let currentState = Object.assign({}, state);
            currentState.isMeropShowed = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.LOAD_REGION_DATA: {
            let currentState = Object.assign({}, state);
            currentState.regionData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.LOAD_RADAR_DATA: {
            let currentState = Object.assign({}, state);
            currentState.radarData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.LOAD_RECOMMENDATIONS_DATA: {
            let currentState = Object.assign({}, state);
            currentState.recomData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.LOAD_SECOND_RADAR_DATA: {
            let currentState = Object.assign({}, state);
            currentState.secondRadarData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.LOAD_STATISTIC_DATA: {
            let currentState = Object.assign({}, state);
            currentState.statisticData = action.payload;
            return updateObject(state, currentState);
        }

        case actions.general.SET_CALL_CODE: {
            let currentState = Object.assign({}, state);
            currentState.callCode = action.payload;
            currentState.needReload = true;
            return updateObject(state, currentState);
        }

        default:
            return state;
    }
};
