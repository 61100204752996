import React from 'react';


interface ISourcePopover {
    src : any[];
}

function SourcePopoverText(props: ISourcePopover) {

    let result;
    if (props.src.length > 0) {
        result = props.src.map(function(tip : any) {
            return (<div key={Math.random()}><a href={tip.link}>{tip.name}</a><br /></div>);
        });
    }
    else
        result = (<p>Источник неизвестен</p>);

    return ( <div>{result}</div> );
}

export default SourcePopoverText;
