import React from 'react';

import MapLegend from './MapLegend';

function MapHeader() {
    return (
        <div className="MapHeader">
            <MapLegend />
        </div>
    );
}

export default MapHeader;
