import React, { useState } from 'react';
import dataService from '../services/dataService';
import { Button } from 'antd';
import AppState from '../types/AppState';
import { connect } from 'react-redux';
import actions from '../store/actions';
import MeropModal from './MeropModal';

interface IHeaderProps {
    sectionData: any[];
    sectionId: number;
    setSectionId: any;
    isMeropShowed: boolean;
    setMeropShowed: any;
}

function Header(props: IHeaderProps) {
    const [modalVisible, setModalVisible] = useState(false);

    const onModalClose = () => {
        setModalVisible(false);
        props.setSectionId(1);
        dataService.loadParams(1);
    };

    const showModalHandler = () => {
        setModalVisible(true);
    };

    const changeSectionHandler = async (section_id: number) => {
        if (section_id === 1) {
            if (props.isMeropShowed) {
                props.setSectionId(1);
                dataService.loadParams(1);
            } else {
                props.setMeropShowed(true);
                showModalHandler();
            }
        } else {
            props.setSectionId(section_id);
            await dataService.loadParams(section_id);
        }
    };

    const section_buttons = props.sectionData.map((section: any) => (
        <Button
            key={Math.random()}
            className="vacancies__button"
            type={section.id === props.sectionId ? 'primary' : 'default'}
            onClick={async () => {
                await changeSectionHandler(section.id);
            }}
        >
            {' '}
            {section.name}
        </Button>
    ));

    return (
        <div className="Header">
            <div className="Header-info">
                <div className="Header-info__title">
                    <img
                        className="Header-logo__rosmol"
                        src={process.env.PUBLIC_URL + '/img/rosmol_logo.png'}
                        alt=""
                    />
                    <div className="color-red beta-version">
                        <b>beta 1.0</b>
                    </div>
                </div>
                <div className="Header-info__subtitle">&nbsp;</div>
            </div>
            <div className="Header-buttons">{section_buttons}</div>
            <div className="Header-support">
                <div className="Header-support__to-email">
                    О&nbsp;найденных&nbsp;ошибках&nbsp;
                    <br />
                    вы&nbsp;можете&nbsp;сообщить&nbsp;
                    <a href="mailto:a.lesovodskaya@digitalskills.center">здесь</a>.
                </div>
            </div>

            <MeropModal visible={modalVisible} onClose={onModalClose} />
        </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        sectionData: store.general.sectionData,
        sectionId: store.general.sectionId,
        isMeropShowed: store.general.isMeropShowed,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSectionId: (id: number) => dispatch(actions.general.setSectionId(id)),
        setMeropShowed: (x: boolean) => dispatch(actions.general.setMeropShowed(x)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
