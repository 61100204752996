import React from 'react';

import AppState from '../types/AppState';
import { connect } from 'react-redux';
import {Modal} from "antd";

interface IMeropProps {
    visible: boolean;
    onClose: () => void;
}

function MeropModal(props: IMeropProps) {

    return (
        <Modal visible={props.visible} onCancel={props.onClose} footer={null} width={'95%'}>
            <h2>Мероприятия</h2>
            <div>
                В этом разделе представлены мероприятия за 2019 год из сводной базы, содержащей следующие источники: <br />
                1) Министерство культуры РФ, <br />
                2) Timepad, <br />
                3) Leader-ID, <br />
                4) АИС Молодежь. <br />
                Темы мероприятий определялись с помощью семантического анализа названий и описаний мероприятий средствами искусственного интеллекта и могут отличаться от направлений, указанных в системе АИС.
            </div>
        </Modal>
    );
}

const mapStateToProps = (store: AppState) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeropModal);
