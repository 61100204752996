import {
    setSectionId,
    setParamId,
    setRegionId,
    setRegionName,
    setIsLoadingAllData,
    setMeropShowed
} from './general';

const general = {
    setSectionId,
    setParamId,
    setRegionId,
    setRegionName,
    setIsLoadingAllData,
    setMeropShowed
};

export default { general };
