import React, { useState } from 'react';

import AppState from '../types/AppState';
import { connect } from 'react-redux';
import Chart from './Chart';

import ChartParams from '../types/ChartParams';

interface IRegionChartProps {
    statisticData: any[];
    paramData: any[];
    paramId: number;
}

function RegionChart(props: IRegionChartProps) {
    const [limit, setLimit] = useState(10);

    let valres = 0;
    for (var ind in props.statisticData) {
        valres = valres + props.statisticData[ind].value;
    }
    valres = valres / props.statisticData.length;

    //console.log('sdata', props.statisticData);

    const top = props.statisticData
        .sort((a: any, b: any) => {
            return a.value > b.value ? -1 : 1;
        })
        .slice(0, limit);

    const loadMore = () => {
        setLimit(limit + 100);
    };

    let postfix = '';
    props.paramData.forEach(data => {
        let currentParam = data.indices.find((item: any) => item.id === props.paramId);
        if (currentParam) postfix = currentParam.postfix;
    });

    const chartParams: ChartParams = {
        min: [0],
        max: [0],
        items: [],
    };

    const firstStat = props.statisticData.find(stat => stat.region_id === 0);

    if (firstStat && firstStat.value)
        chartParams.items.push({
            id: {
                res_code: '',
                res_name: '',
            },
            title: 'Показатель по РФ',
            data: [firstStat.value.toFixed(0).replace(/\./g, ',')],
            colors: ['#61cac5'],
        });

    top.forEach(item => {

        if (item.iso3166 && item.value && item.region_id !== 0) {
            //console.log('push', item);
            chartParams.items.push({
                id: {
                    res_code: item.region_id,
                    res_name: item.name,
                },
                title: item.name,
                data: [[item.value.toFixed(0).replace(/\./g, ',')]],
            });

            if (item.value > chartParams.max[0]) chartParams.max[0] = item.value;

            chartParams.colors = [['#2a4d8b']];
            chartParams.postfix = [[postfix]];
            chartParams.labels = [['Показатель']];
        }
    });

    const chartHandleClick = async (regionId: any) => {
        //console.log('RegionChart clicked: ', regionId);
    };

    return (
        <div style={{ marginLeft: '20px' }}>
            <Chart
                params={chartParams}
                chartHandleClick={chartHandleClick}
                showLoadMore={top.length % 10 === 0}
                loadMore={loadMore}
            />
        </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        statisticData: store.general.statisticData,
        paramData: store.general.paramData,
        paramId: store.general.paramId,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionChart);
