import React from 'react';
import AppState from '../types/AppState';
import { connect } from 'react-redux';
import RegionChart from './RegionChart';
import { Typography } from 'antd';

const { Title } = Typography;

interface IRightSidebarProps {
    paramData: any[];
    paramId: number;
}

function Rightsidebar(props: IRightSidebarProps) {

    let indice = '';
    for (var p in props.paramData) {
        for (var p2 in props.paramData[p].indices) {
            if (props.paramData[p].indices[p2].id === props.paramId)
                indice = props.paramData[p].indices[p2].description;
        }
    }

    return (
        <div className="sidebar sidebar_right">
            <Title level={4} style={{ textAlign: 'center' }}>
                {indice}
            </Title>
            <RegionChart />
        </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        paramData: store.general.paramData,
        paramId: store.general.paramId,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rightsidebar);
