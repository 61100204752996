import React, { useEffect } from 'react';
import AppState from '../types/AppState';
import { connect } from 'react-redux';


interface MapLegendGradientProps {
    statisticData: any[];
    colorGradient : any[];
    range_max : number;
    range_min : number;
}

function MapLegendGradient(props: MapLegendGradientProps) {
    useEffect(() => {}, [props]);

    const colorsGradient = props.colorGradient

    let minval = props.range_min, maxval = props.range_max;
    for (var regstat in props.statisticData)
    {
        if (props.statisticData[regstat].value < minval) minval = props.statisticData[regstat].value;
        if (props.statisticData[regstat].value > maxval) maxval = props.statisticData[regstat].value;
    }

    let colorsNumbers = [];
    for (let i = 0; i <= 3; i++) {
        let number: any = 0;
        //if(i > 0) number = Number(chroma.limits(dataForColors, 'l', 3)[i].toFixed(0));
        number = Math.round(minval + (maxval - minval)*i*100/300);
        colorsNumbers.push({
            interest: (100 / 3) * i,
            number: number,
        });
    }

    let colorsGradientsStyle = 'linear-gradient(to right,';
    colorsGradientsStyle += colorsGradient.map(color => {
        return color;
    });
    colorsGradientsStyle += ')';

    let myGradStyle = 'linear-gradient(to right, #a3c7fa, #a3c7fa)';

    return (
        <div className="MapLegendGradient">
            <div
                className="MapLegendGradient__line"
                style={{ backgroundImage: colorsGradientsStyle }}
            >
                <div className="MapLegendGradient__items">
                    {colorsNumbers.map((item, index) => {
                        return (
                            <div
                                className="MapLegendGradient__item"
                                style={{ left: item.interest + '%' }}
                                key={'MapLegendGradient__item-' + index}
                            >
                                {item.number}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className="MapLegendGradient__line"
                style={{ backgroundImage: myGradStyle }}
            >&nbsp;Нет данных&nbsp;
            </div>

        </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        statisticData: store.general.statisticData,
    };
};

export default connect(mapStateToProps)(MapLegendGradient);
