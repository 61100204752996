class CombinationsService {
    public getCombinations(chars: any) {
        let result: any[] = [];
        let f = (prefix: string, chars: string | any[]) => {
            for (let i = 0; i < chars.length; i++) {
                result.push(prefix + chars[i]);
                f(prefix + chars[i], chars.slice(i + 1));
            }
        };
        f('', chars);
        return result;
    }
}

const combinationsService = new CombinationsService();
export default combinationsService;
