import React from 'react';
import AppState from "../types/AppState";
import {connect} from "react-redux";
import HTMLReactParser from "html-react-parser";


function RecomText(props : any)
{
    let g_points : string = '';

    let recom_element = props.recomData.find((recom : any) => recom.region_id === props.regionId);
    if (recom_element) {
        g_points = recom_element.growth_points;
    }

    for (var i = 0; i < 9; i++)
        g_points = g_points.replace('\n','<br/>');

    return (
        <>
            <h3>Точки роста</h3>
            <p>{HTMLReactParser(g_points)}</p>
        </>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        regionId: store.general.regionId,
        recomData : store.general.recomData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //setParamId: (param_id : number) => dispatch(actions.general.setParamId(param_id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecomText);