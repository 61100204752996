import React from 'react';
import {Modal, Popover} from 'antd';
import AppState from '../types/AppState';
import { connect } from 'react-redux';

import ModalChart from './ModalChart';
import ChartParams from '../types/ChartParams';
import SourcePopoverText from "./SourcePopoverText";
import RadarChartProps from "../types/RadarChartProps";
import RadarChart from "./RadarChart";
import RecomText from "./RecomText";

interface RegionModalProps {
    visible: boolean;
    onClose: () => void;
    //------------
    regionId: number;
    regionName: string;
    regionData: any[];
    paramData: any[];
    secondRadarData : any[];
    radarData: any[];
}

function RegionModal(props: RegionModalProps) {

    let molkol : string = '';

    function FormChart(indices: any[]) {
        let cp: ChartParams = { min: [0], max: [0, 0], items: [] };
        let tipresults : any;
        indices.map((detail: any) => {
            if (detail['value']) {
                if (detail['id'] === 137)
                {
                    molkol = detail['value'].toFixed(0);
                }
                else {
                    cp.items.push({
                        id: detail['id'],
                        title: detail['name'],
                        data: [
                            [
                                detail['value'].toFixed(0).replace(/\./g, ',') +
                                (detail['postfix'] == null ? '' : detail['postfix']),
                            ],
                            detail['rus_avg']
                                ? [
                                    detail['rus_avg'].toFixed(0).replace(/\./g, ',') +
                                    (detail['postfix'] == null ? '' : detail['postfix']),
                                ]
                                : '0',
                        ],
                    });
                    if (detail['value'] > cp.max[0]) cp.max[0] = detail['value'];
                    if (detail['rus_avg'] > cp.max[0]) cp.max[0] = detail['rus_avg'];
                    cp.max[1] = cp.max[0];

                    let tooltips: any = [];
                    if (detail.sources)
                        detail.sources.map((src: any) => {
                            if (!tooltips.find((item: any) => item.name === src.name)) tooltips.push({
                                name: src.name,
                                link: src.link
                            });
                            return null;
                        });
                    tipresults = (<SourcePopoverText src={tooltips}/>);
                }
            }
            return null;
        });

        cp.colors = [['#2a4d8b'], ['#26e7a6']];
        cp.labels = [['по региону'], ['по России']];
        cp.popover = tipresults;
        return cp;
    }

    function MakeLabel(label: string, color: string) {
        return (
            <div className="r-charts-label" key={'r-charts-label-' + Math.random()}>
                <div className="r-charts-label__color" style={{ backgroundColor: color }} />
                <span className="r-charts-label__title">{label}</span>
            </div>
        );
    }

    const labels = [
        MakeLabel('Значение показателя по региону', '#2a4d8b'),
        MakeLabel('Значение показателя по России', '#26e7a6'),
    ];

    const regions =
        props.regionData.length > 0
            ? props.regionData.map((gr: any) => (
                  <div className="modal-rows__row" key={Math.random()}>
                      <h3>
                          <b>{gr.name}</b>
                      </h3>
                      {gr.groups.map((detail: any) => {
                          return (
                              <div key={Math.random()}>
                                  <p>&nbsp; {detail.name} </p>
                                  <ModalChart chartParams={FormChart(detail.indices)} />
                              </div>
                          );
                      })}
                  </div>
              ))
            : '&nbsp;';

    let local_cp = { "crime": 0, "health": 0, "family": 0, "economics": 0, "activity": 0, "education": 0};
    let russia_cp = { "crime": 0, "health": 0, "family": 0, "economics": 0, "activity": 0, "education": 0};
    if (props.radarData.length > 0) {
        local_cp = props.radarData.find(el => (el.region_id === props.regionId));
        if (local_cp === undefined)
            local_cp = { "crime": 0, "health": 0, "family": 0, "economics": 0, "activity": 0, "education": 0}
        russia_cp = props.radarData.find(el => (el.region_id === 0));
    }
    //console.log('loc', local_cp, russia_cp);

    let cp : RadarChartProps = {
        series: [{
            name: '-',
            data:  [Number(local_cp.crime.toFixed(2)),
                    Number(local_cp.economics.toFixed(2)),
                    Number(local_cp.family.toFixed(2)),
                    Number(local_cp.health.toFixed(2)),
                    Number(local_cp.activity.toFixed(2)),
                    Number(local_cp.education.toFixed(2))],
        },
            {
                name: '-',
                data: [ Number(russia_cp.crime.toFixed(2)),
                        Number(russia_cp.economics.toFixed(2)),
                        Number(russia_cp.family.toFixed(2)),
                        Number(russia_cp.health.toFixed(2)),
                        Number(russia_cp.activity.toFixed(2)),
                        Number(russia_cp.education.toFixed(2))],
            }],
        options: {
            chart: {
                height: '350',
                type: 'radar',
                animations: {
                    enabled: true,
                    speed: 10
                }
            },
            legend : {
                show : false,
            },
            title: {
                text: 'Профиль региона'
            },
            xaxis: {
                categories: ['Законопослушность', 'Экономика', 'Семья', 'Здоровье', 'Активность', 'Образование']
            }
        },
    };
    //---------------------------------------------
    let cp2 : RadarChartProps = {
        series: [{
            name: '-',
            data:  [],
        },
            {
                name: '-',
                data: [],
            }],
        options: {
            chart: {
                height: '350',
                type: 'radar',
                animations: {
                    enabled: true,
                    speed: 10,
                }
            },
            legend : {
                show : false,
            },
            title: {
                text: 'Профиль мероприятий'
            },
            xaxis: {
                categories: [],
                labels : {
                    show : true
                }
            }
        },
    };


    let second_local_cp, second_russia_cp : any;
    if (props.secondRadarData.length > 0) {
        second_local_cp = props.secondRadarData[0];
        second_russia_cp = props.secondRadarData[1];
        //console.log('cp_lc', second_local_cp, second_russia_cp, props.secondRadarData, props.regionId);
        if (second_local_cp) {
            second_local_cp.forEach(function (value: any, i: number) {
                cp2.options.xaxis.categories.push(value.short_name);
                cp2.series[0].data.push(Number(value.value.toFixed(2)));
                let val2 = second_russia_cp.find((el:any) => (el.fadm_id === value.fadm_id));
                if (val2)
                    cp2.series[1].data.push(Number(val2.value.toFixed(2)));
                else
                    cp2.series[1].data.push(0);
            });
        }
    }

    const chart_2_popover = (
        <>
            В данном радаре представлено распределение мероприятий, ориентированных на молодёжь за 2019 год из следующих источников: <br/>
            1) Министерство культуры РФ (с тегом «для молодёжи»), <br/>
            2) АИС Молодежь, <br/>
            3) Leader-ID, <br/>
            4) Timepad. <br/>
            Молодёжные мероприятия TimePad и LeaderID были определены на основе семантического анализа. Отобранные мероприятия были размечены на основе семантического анализа по темам. В дальнейшем анализе участвовали те мероприятия, у которых семантическим анализом была найдена хотя бы одна тема. Темы были объединены в 8 групп мероприятий: Творчество, ЗОЖ, Патриотизм (включает темы Патриотическое воспитание и Российская идентичность), Семья, Профессия (включает темы Инновации и Карьера), Общество (включает темы СМИ, Организации, Самоуправление, Международное сотрудничество), Волонтёрство, Социализация (включает темы Молодёжь в социально-опасном положении и Молодёжь, нуждающаяся в особой заботе). Для РФ и регионов были посчитаны показатели: число мероприятий по каждой из групп (одно мероприятие могло входить в несколько групп) и общее число мероприятий. В радаре показано отношение этих двух показателей.
        </>
    );
    const chart_1_popover = (
        <>
            В данном радаре представлены рейтинговые показатели региона по группам (в скобках указаны показатели, входящие в группы): Преступность (Несовершеннолетние преступники, Учащиеся/студенты преступники), Здоровье (Психическими расстройства из-за алкоголя, Психические расстройства из-за наркотиков, Спорт детей и молодежи, Инвалиды среди молодежи), Семья (Беспризорные несовершеннолетние, Аборты девушек 15-17 лет, Нуждающиеся в жилье молодые семьи), Экономическое положение: (Вакансии для молодежи, Жилищные условия молодых семей, Занятость молодежи), Образование (Доля студентов вузов, Доля студентов СПО, Бюджетные места вузов, Количество участников НЛСК на 10 000 молодежи), Активность (Пользователи ЕИС, Избирательная явка). Всем регионам были присвоены значения от 1 до 100 по показателям, входящим в группы рейтинга, где 100 соответвует лучше ситуации в стране, а 1 - худшей. Рейтинговые баллы региона по группам были рассчитаны как среднее арифметическое по входящим в эту группу показателям.
        </>
    );

    return (
        <Modal visible={props.visible} onCancel={props.onClose} footer={null} width={'95%'}>
            <h2>{props.regionName}</h2>
            <h3>Общее количество молодежи в регионе: {molkol} тыс. чел.</h3>
            <div className="modal-rows" key={Math.random()}>
                <div className="modal-rows__row" key={Math.random()}>
                    <Popover content={chart_1_popover} title={"Радар региона"} mouseEnterDelay={1}>
                        <div>
                            <RadarChart series={cp.series} options={cp.options}/>
                        </div>
                    </Popover>
                </div>
                <div className="modal-rows__row" key={Math.random()}>
                    <Popover content={chart_2_popover} title={"Радар мероприятий"} mouseEnterDelay={1}>
                        <div>
                            <RadarChart series={cp2.series} options={cp2.options}/></div>
                    </Popover>
                </div>
                <div className="modal-rows__row" key={Math.random()}>
                    <RecomText />
                </div>
            </div>
            <div className="modal-rows" key={Math.random()}>
                {labels}
            </div>
            <br/><br/>
            <div className="modal-rows" key={Math.random()}>
                {regions}
            </div>
        </Modal>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        regionData: store.general.regionData,
        regionId: store.general.regionId,
        regionName: store.general.regionName,
        paramData: store.general.paramData,
        radarData : store.general.radarData,
        secondRadarData : store.general.secondRadarData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //setParamId: (param_id : number) => dispatch(actions.general.setParamId(param_id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegionModal);
