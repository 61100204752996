import React from 'react';

import AppState from '../types/AppState';
import { connect } from 'react-redux';
import Chart from "./Chart";

import ChartParams from '../types/ChartParams';


interface IModalChartProps {
    statisticData: any[];
    paramData: any[];
    paramId: number;
    chartParams : ChartParams;
}

function ModalChart(props: IModalChartProps) {

    const chartHandleClick = async (regionId: any) => {
        console.log('ModalChart clicked: ', regionId);
    };
    const loadMore = () => {
        //
    };
    return (
            <div style={{ marginLeft: '20px' }} key={Math.random()}>
                <Chart
                    params={props.chartParams}
                    chartHandleClick={chartHandleClick}
                    showLoadMore={false}
                    loadMore={loadMore}
                />
            </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        statisticData : store.general.statisticData,
        paramData: store.general.paramData,
        paramId: store.general.paramId
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChart);
