import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import App from "./App";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { createStore, Store } from "redux";
import { Provider } from "react-redux";

import reducers from "./store/reducers";

import storeService from "./services/storeService";

import { composeWithDevTools } from "redux-devtools-extension";
import combinationsService from "./services/combinationsService";

const store: Store = createStore(reducers, {}, composeWithDevTools());

storeService.setStore(store);
//dataService.preloadAllData();

const routerPaths = combinationsService.getCombinations([
  "/sectionId/:sectionId",
  /*
    '/dataMode/:dataMode',
    '/activeRegion/:activeRegion',
    '/activeCity/:activeCity',
    '/skillId/:skillId',
    '/specId/:specId',
    '/subjectAreaId/:subjectAreaId'*/
]);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path={routerPaths} component={App} />
        <Route component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
