import React, { createRef, useState } from 'react';
import { YMaps, Map, ZoomControl } from 'react-yandex-maps';
import actions from '../../store/actions';
import AppState from '../../types/AppState';
import { connect } from 'react-redux';

import MapFooter from '../MapFooter';
import MapHeader from '../MapHeader';
import RegionModal from '../RegionModal';

import chroma from 'chroma-js';

import dataService from '../../services/dataService';
import { message } from 'antd';

const mapState = {
    center: [62.0, 105.0],
    zoom: 3,
};

// const COLORS = ['#726A95', '#709FB0', '#A0C1B8', '#F4EBC1'];

interface IBmkMapProps {
    statisticData: any[];
    setRegionId: any;
    setRegionName: any;
    paramData: any[];
    paramId: number;
}

function BmkMap(props: IBmkMapProps) {
    const [modalVisible, setModalVisible] = useState(false);

    const mapRef: any = createRef();
    // Генерация цветов
    let colors = ['#cb1225', '#fdd017', '#029360'];
    let max_range = 0.0;
    let min_range = 0.0;
    for (var pr_key in props.paramData)
        for (var ind_key in props.paramData[pr_key].indices) {
            if (props.paramData[pr_key].indices[ind_key].id === props.paramId) {

                if (props.paramData[pr_key].indices[ind_key].has_inverted_colors) {
                    colors = ['#029360', '#fdd017', '#cb1225'];
                }
                max_range = props.paramData[pr_key].indices[ind_key].range_max;
                min_range = props.paramData[pr_key].indices[ind_key].range_min;

            }
        }
    let colorsGradient = chroma.scale(colors).colors(60);

    const onModalClose = () => {
        setModalVisible(false);
    };

    const setObjects = (ymaps: any) => {
        if (mapRef && mapRef.current) {
            const objectManager = new ymaps.ObjectManager();
            ymaps.borders
                .load('RU', {
                    lang: 'ru',
                    quality: 2,
                })
                .then(function(result: any) {
                    // Создадим объект regions, где ключи это ISO код региона.

                    var regions = result.features.reduce(function(acc: any, feature: any) {
                        // Добавим ISO код региона в качестве feature.id для objectManager.
                        var iso = feature.properties.iso3166;
                        feature.id = iso;
                        // Добавим опции региона по умолчанию.
                        feature.options = {
                            fillOpacity: 0.6,
                            strokeColor: '#FFF',
                            strokeOpacity: 0.5,
                        };

                        acc[iso] = feature;
                        return acc;
                    }, {});

                    // раскраска регионов
                    if (props.statisticData.length > 0) {
                        for (var regstat in props.statisticData) {
                            if (props.statisticData[regstat].value < min_range)
                                min_range = props.statisticData[regstat].value;
                            if (props.statisticData[regstat].value > max_range)
                                max_range = props.statisticData[regstat].value;
                        }

                        for (var rst2 in props.statisticData) {
                            let colorIndex = -1;

                            if (props.statisticData[rst2].value !== null) {
                            colorIndex = Math.floor(
                                ((props.statisticData[rst2].value - min_range) * 59) /
                                    (max_range - min_range)
                            );
                            }

                            if (
                                typeof regions[props.statisticData[rst2].iso3166] !== 'undefined' &&
                                colorIndex !== -1
                            ) {
                                let color = colorsGradient[colorIndex];
                                regions[
                                    props.statisticData[rst2].iso3166
                                    ].options.fillColor = color; //colorsGradient[colorIndex];
                            }
                        }
                        //console.log('gradient', colorsGradient);
                    }

                    // Добавим регионы на карту.
                    result.features = [];
                    for (var reg in regions) {
                        result.features.push(regions[reg]);
                    }

                    objectManager.add(result);

                    objectManager.objects.events.add('click', (e: any) => {
                        e.preventDefault();
                        let newActiveRegion = e._sourceEvent.originalEvent.objectId;

                        if (window.screen.width < 1024)
                            return message.warning(
                                'Извините, данный функционал не доступен в мобильной версии. Перейдите на ПК версию.'
                            );

                        for (var rst3 in props.statisticData)
                            if (props.statisticData[rst3].iso3166 === newActiveRegion) {
                                props.setRegionId(props.statisticData[rst3].region_id);
                                props.setRegionName(regions[newActiveRegion].properties.name);
                                dataService.loadRegionInfo(props.statisticData[rst3].region_id);
                                dataService.loadRadar(props.statisticData[rst3].region_id);
                                dataService.loadRadarSecond(props.statisticData[rst3].region_id);
                                dataService.loadRecommendations(props.statisticData[rst3].region_id);
                                setModalVisible(true);
                                break;
                            }
                    });

                    mapRef.current.geoObjects.add(objectManager);
                });
        }
    };

    return (
        <>
            <YMaps>
                <div className="Map">
                    <MapHeader />
                    <Map
                        width="100%"
                        height="calc(100% + 90px)"
                        // Создаем ссылку на инстанс мапа, чтобы использовать его
                        instanceRef={mapRef}
                        state={mapState}
                        // Используем коллбэк функцию при загруе карты
                        onLoad={ymaps => setObjects(ymaps)}
                        // Подключаем модули регионов и ObjectManager
                        modules={[
                            'borders',
                            'ObjectManager',
                            'layout.PieChart',
                            'geoObject.addon.balloon',
                            'geoObject.addon.hint',
                            'package.full',
                        ]}
                    >
                        <ZoomControl options={{ float: 'right' }} />
                    </Map>
                    <MapFooter colorGradient={colorsGradient} range_max={max_range} range_min={min_range}/>
                </div>
            </YMaps>
            <RegionModal visible={modalVisible} onClose={onModalClose} />
        </>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        statisticData: store.general.statisticData,
        paramData: store.general.paramData,
        paramId: store.general.paramId,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setRegionId: (region_id: number) => dispatch(actions.general.setRegionId(region_id)),
        setRegionName: (region_name: string) =>
            dispatch(actions.general.setRegionName(region_name)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BmkMap);
