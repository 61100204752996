import React from "react";
import { Form, Input, Button } from "antd";
import AppState from "../types/AppState";
import { connect } from "react-redux";
import dataService from "../services/dataService";

interface ILoginBox {
  //colorGradient : any[];
  //range_max : number;
  //range_min : number;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const onFinish = async (values: any) => {
  dataService.setCallCode(values.password);
  dataService.preloadAllData();
};

function LoginBox(props: ILoginBox) {
  return (
    <React.Fragment>
      <h2>Для продолжения введите пароль</h2>
      <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Для продолжения введите пароль!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Применить
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

const mapStateToProps = (store: AppState) => {
  return {
    callCode: store.general.callCode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    //
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginBox);
