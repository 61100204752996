import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import RadarChartProps from "../types/RadarChartProps";



function RadarChart(props : RadarChartProps)
{
    const [params, setParams] = useState({
        props
    });

    useEffect(() => {
        setParams({props
        });
    }, [props]);

    return (
        <div id="chart" key={'r-charts-item__chart-group-' + Math.random()}>
            <ReactApexChart options={params.props.options} series={params.props.series} type="radar" height={350} />
        </div>
    );
}

export default RadarChart;