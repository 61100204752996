import actionTypes from './actionTypes';


export const setSectionId = (payload: number) => {
    return {
        type: actionTypes.general.SET_SECTION_ID,
        payload,
    };
};

export const setParamId = (payload: number) => {
    return {
        type: actionTypes.general.SET_PARAM_ID,
        payload,
    };
};

export const setRegionId = (payload: number) => {
    return {
        type: actionTypes.general.SET_REGION_ID,
        payload,
    };
};

export const setRegionName = (payload: string) => {
    return {
        type: actionTypes.general.SET_REGION_NAME,
        payload,
    };
};


export const setIsLoadingAllData = (payload: boolean) => {
    return {
        type: actionTypes.general.SET_IS_LOADING_ALL_DATA,
        payload,
    };
};

export const setMeropShowed= (payload: boolean) => {
    return {
        type: actionTypes.general.SET_MEROP_SHOWED,
        payload,
    };
};
