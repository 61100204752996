import React from "react";
import Header from "./components/Header";

import "./App.css";
import Leftsidebar from "./components/Leftsidebar";
import Rightsidebar from "./components/Rightsidebar";
import AppState from "./types/AppState";
import { connect } from "react-redux";
import { Spin, Row, Col, Layout } from "antd";
//import RegionMap from './components/maps/RegionMap';
//import DataModeSwitch from './components/DataModeSwitch';

import BmkMap from "./components/maps/BmkMap";
import LoginBox from "./components/LoginBox";
import dataService from "./services/dataService";

interface IAppProps {
  mode: string;
  isLoadingAllData: boolean;
  callCode: string;
  needReload: boolean;
}

function App(props: IAppProps) {
  window.onpopstate = () => {
    // eslint-disable-next-line
    window.location = window.location;
  };

  if (props.callCode === "") {
    return (
      <div className="wrapper">
        <div className="container">
          <Layout>
            <Row>
              <Col span={8} offset={8}>
                <LoginBox />
              </Col>
            </Row>
          </Layout>
        </div>
      </div>
    );
  }

  if (props.needReload) dataService.preloadAllData();

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        {props.isLoadingAllData ? (
          <Spin className="page-loading" size="large" tip="Загрузка..." />
        ) : (
          <div className="main">
            <Leftsidebar />
            <BmkMap />
            <Rightsidebar />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (store: AppState) => {
  return {
    isLoadingAllData: store.general.isLoadingAllData,
    callCode: store.general.callCode,
    needReload: store.general.needReload,
  };
};
export default connect(mapStateToProps)(App);
