const actions = {
    general: {
        SET_SECTION_ID: 'SET_SECTION_ID',
        SET_SECTION_DATA: 'SET_SECTION_DATA',
        SET_PARAM_ID: 'SET_PARAM_ID',
        SET_PARAM_DATA: 'SET_PARAM_DATA',
        LOAD_STATISTIC_DATA: 'LOAD_STATISTIC_DATA',
        SET_REGION_ID : 'SET_REGION_ID',
        SET_REGION_NAME : 'SET_REGION_NAME',
        LOAD_REGION_DATA : 'LOAD_REGION_DATA',
        LOAD_RADAR_DATA : 'LOAD_RADAR_DATA',
        LOAD_RECOMMENDATIONS_DATA : 'LOAD_RECOMMENDATIONS_DATA',
        LOAD_SECOND_RADAR_DATA : 'LOAD_SECOND_RADAR_DATA',
        SET_IS_LOADING_ALL_DATA: 'SET_IS_LOADING_ALL_DATA',
        SET_MEROP_SHOWED: 'SET_MEROP_SHOWED',
        SET_CALL_CODE: 'SET_CALL_CODE'
    },
};

export default actions;
