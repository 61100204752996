import React from 'react';

import AppState from '../types/AppState';
import { connect } from 'react-redux';
import { Modal } from 'antd';

interface IRFProps {
    visible: boolean;
    onClose: () => void;
}

function RFModal(props: IRFProps) {
    return (
        <Modal visible={props.visible} onCancel={props.onClose} footer={null} width={'95%'}>
            <h2>Показатели по РФ</h2>
            <div>
                <br />
                <img
                    style={{ maxWidth: '100%', margin: '0 auto' }}
                    src={process.env.PUBLIC_URL + '/img/mero.png'}
                    alt={'Мероприятие'}
                />
                <br />
                <a href={process.env.PUBLIC_URL + '/merop_rf.pdf'}>Подробнее...</a>
                <br />
            </div>
        </Modal>
    );
}

const mapStateToProps = (store: AppState) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RFModal);
