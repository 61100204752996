import React, { useEffect } from 'react';
import AppState from '../types/AppState';
import { connect } from 'react-redux';


interface IMapLegendProps {
    statisticData: any[];
    paramData: any[];
    paramId: number;
}

function MapLegend(props: IMapLegendProps) {
    useEffect(() => {}, [props]);

    let indice = '';
    for (let p in props.paramData) {
        for (let p2 in props.paramData[p].indices) {
            if (props.paramData[p].indices[p2].id === props.paramId)
                indice = props.paramData[p].indices[p2].description;
        }
    }

    return (
        <div className="MapLegend">
            <div className="MapLegend__container">
                {indice && (
                    <span className="MapLegend__marker">
                        <span className="MapLegend__marker-count">{indice}</span>
                    </span>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (store: AppState) => {
    return {
        statisticData: store.general.statisticData,
        paramData: store.general.paramData,
        paramId: store.general.paramId,
    };
};

export default connect(mapStateToProps)(MapLegend);
