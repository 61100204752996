import { Store } from 'redux';
import { cloneDeep } from 'lodash';
import { initialState as initialGeneralState } from '../store/reducers/general';
import GeneralState from '../types/GeneralState';

type ChangeHandler = () => void;
class StoreService {
    store: Store | null;
    currentGeneral: GeneralState;
    changeHandlers: ChangeHandler[];

    constructor(store: Store | null = null) {
        this.store = store;
        this.currentGeneral = cloneDeep(initialGeneralState);
        this.changeHandlers = [];
    }

    handleChange = () => {
        let previousValue = cloneDeep(this.currentGeneral);

        this.currentGeneral = cloneDeep(this.store?.getState().general);

        if (
            JSON.stringify(previousValue) !==
            JSON.stringify(this.currentGeneral)
        ) {
            this.changeHandlers.forEach(handler => {
                handler();
            });
        }
    };

    setChangeHandler = (handler: () => void) => {
        if (this.store) {
            this.changeHandlers.push(handler);
            this.store.subscribe(this.handleChange);
        }
    }

    setStore = (store: Store) => {
        this.store = store;
        this.currentGeneral = cloneDeep(store.getState().general);
    };

    getStore = (): Store => {
        if (this.store !== null) {
            return this.store;
        } else throw new Error('Trying to access unitialized store');
    };
}
const storeService = new StoreService();

export default storeService;
