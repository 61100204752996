import React, { useEffect, useState } from 'react';
import ChartParams from '../types/ChartParams';
import { Popover, Tooltip } from 'antd';


interface ChartProps {
    chartHandleClick(id: any): void;
    loadMore(): void;
    showLoadMore: boolean;
    params: {
        items: {
            id: any;
            title: string;
            data: (string | string[])[];
            ratio?: number[];
        }[];
        colors?: (string | string[])[];
        postfix?: any[];
        lineMaxWidth?: number;
        align?: string;
        tooltip?: string;
        popover?: string;
        labels?: (string | string[])[];
    };
}

function Chart(props: ChartProps) {
    const [params, setParams] = useState({
        min: [],
        max: [],
        ...props.params,
    } as ChartParams);

    useEffect(() => {
        setParams({
            min: [],
            max: [],
            ...props.params,
        } as ChartParams);
    }, [props]);

    if (params.items.length === 0)
        return (
            <div className="r-charts">
                <p className="r-charts__empty">Данных нет</p>
            </div>
        );

    /**
     * Создание массива минимальных и максимальных значений для наборов данных
     */

    /**
     * Разделение чисел на разрядам
     * @param x
     */

    // Легенды для графика
    let labelsData = params.labels?.join().split(',');
    let colorsData = params.colors?.join().split(',');

    labelsData?.push('НД – нет данных');
    colorsData?.push('#d0d0d0');

    /*
    const labels = labelsData?.map((label, labelIndex) => {
        let color = colorsData ? colorsData[labelIndex] : undefined;

        return (
            <div className="r-charts-label" key={'r-charts-label-' + Math.random()}>
                <div className="r-charts-label__color" style={{ backgroundColor: color }} />
                <span className="r-charts-label__title">{label}</span>
            </div>
        );
    });*/

    const chartItems = params.items.map(item => {
        const chartItemData = item.data;

        // Отрисовка линий в группу (общий <div>)
        const chart = chartItemData.map((itemData, itemDataIndex) => {
            if (!Array.isArray(itemData)) itemData = [itemData];

            // Отрисовка линий
            const chartLines = itemData.map((itemDataLine, itemDataLineIndex) => {
                // Максимальная длина всех линий в группе
                let lineMaxWidth: number =
                    Array.isArray(itemData) && itemData.length > 1 ? 100 : 100;
                // Стили для линий
                let styles: {
                    width: string;
                    backgroundColor?: string;
                } = {
                    width:
                        itemDataLine !== null
                            ? (parseFloat(itemDataLine.replace(/[,]+/g, '.')) /
                                  params.max[itemDataIndex]) *
                                  lineMaxWidth +
                              '%'
                            : '0%',
                };

                // Добавление цветов для линий
                if (Array.isArray(params.colors) && params.colors.length === chartItemData.length) {
                    let backgroundColor = Array.isArray(params.colors[itemDataIndex])
                        ? params.colors[itemDataIndex][itemDataLineIndex]
                        : params.colors[itemDataIndex];

                    if (item.colors) backgroundColor = item.colors[0];
                    if (!Array.isArray(backgroundColor)) styles.backgroundColor = backgroundColor;
                }

                // Добавление символов после числа
                let postfix;
                if (
                    Array.isArray(params.postfix) &&
                    params.postfix.length === chartItemData.length
                ) {
                    postfix = Array.isArray(params.postfix[itemDataIndex])
                        ? params.postfix[itemDataIndex][itemDataLineIndex]
                        : params.postfix[itemDataIndex];
                }

                let number = 'НД';
                if (itemDataLine && itemDataLine !== '0') {
                    number = itemDataLine;
                    if (postfix) number += postfix;
                }

                return (
                    <div
                        className="r-charts-item__chart-line"
                        style={styles}
                        key={'r-charts-item__chart-line-' + Math.random()}
                    >
                        {number}
                    </div>
                );
            });

            let chartGroupClasses = ['r-charts-item__chart-group'];
            // Выравнивание по центру
            if (params.align === 'center')
                chartGroupClasses.push('r-charts-item__chart-group_center');

            if (params.align === 'center') {
                let ratio = item.ratio || 0;
                if (ratio && Array.isArray(ratio) && ratio[itemDataIndex]) {
                    let dotNumbers = String(ratio[itemDataIndex]).split('.')[1];
                    if (dotNumbers) {
                        if (dotNumbers.substr(0, 2).length === 0) {
                            ratio = ratio[itemDataIndex];
                        } else {
                            ratio = Number(ratio[itemDataIndex].toFixed(1));
                        }
                    } else {
                        ratio = ratio[itemDataIndex];
                    }
                }

                return (
                    <div
                        className={chartGroupClasses.join(' ')}
                        key={'r-charts-item__chart-group-' + Math.random()}
                        onClick={() => {
                            props.chartHandleClick(item.id);
                        }}
                    >
                        <div className="r-charts-item__chart-group-col r-charts-item__chart-group-col_left">
                            {chartLines.slice(0, 2)}
                        </div>
                        <div className="r-charts-item__chart-group-col r-charts-item__chart-group-col_center">
                            <p className="r-charts-item__chart-group-number">{ratio}</p>
                        </div>
                        <div className="r-charts-item__chart-group-col r-charts-item__chart-group-col_right">
                            {chartLines.slice(2)}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        className={chartGroupClasses.join(' ')}
                        key={'r-charts-item__chart-group-' + Math.random()}
                    >
                        {chartLines}
                    </div>
                );
            }
        });

        const ChartBullet = (
            <div
                className="r-charts-item"
                key={'r-charts-item-' + Math.random()}
                onClick={() => {
                    props.chartHandleClick(item.id);
                }}
            >
                <span className="r-charts-item__title">{item.title}</span>
                <div className="r-charts-item__chart" key={'r-charts-item__chart-' + Math.random()}>
                    {chart}
                </div>
            </div>
        );

        if (params.tooltip)
            return (
                <Tooltip
                    title={params.tooltip}
                    placement="top"
                    key={'r-charts-item__tooltip-' + Math.random()}
                >
                    {ChartBullet}
                </Tooltip>
            );
        if (params.popover)
            return (
                <Popover content={params.popover} title={item.title} mouseEnterDelay={1} key={Math.random()}>
                    {ChartBullet}
                </Popover>
            );
        return ChartBullet;
    });

    return (
        <div className="r-charts">
            <div className="r-charts__data">{chartItems}</div>
            {props.showLoadMore && (
                <div className="r-charts__more" onClick={props.loadMore}>
                    Показать все
                </div>
            )}
            {/*<div className="r-charts-labels">{labels}</div>*/}
        </div>
    );
}

export default Chart;
