import React, { useEffect, useState } from "react";
import AppState from "../types/AppState";
import { connect } from "react-redux";
import actions from "../store/actions";
import dataService from "../services/dataService";
import { Button, Popover } from "antd";
import { Collapse } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import SourcePopoverText from "./SourcePopoverText";
import RFModal from "./RFModal";

const { Panel } = Collapse;

interface ISidebarProps {
  paramData: any[];
  paramId: number;
  setParamId: any;
}

function Leftsidebar(props: ISidebarProps) {
  const [isButtonsShowed, setIsButtonsShowed] = useState(true);
  const screenWidth = window.screen.width;
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (screenWidth <= 1024) setIsButtonsShowed(false);
  }, [screenWidth]);

  const changeParamHandler = async (param_id: number) => {
    props.setParamId(param_id);
    await dataService.loadStatRegions(param_id);
  };

  const onModalClose = () => {
    setModalVisible(false);
  };

  const showModalHandler = () => {
    setModalVisible(true);
  };

  let acc_active_key = 0;

  const param_buttons = props.paramData.map((param: any) => {
    let buttons = param.indices.map((detail: any) => {
      if (detail.id === props.paramId) acc_active_key = param.id;
      let tooltips: any = [];
      let tipresults: any;
      if (detail.sources)
        detail.sources.map((src: any) => {
          if (!tooltips.find((item: any) => item.name === src.name))
            tooltips.push({ name: src.name, link: src.link });
          return null;
        });

      tipresults = <SourcePopoverText src={tooltips} />;
      return (
        <Popover
          content={tipresults}
          title={detail.description}
          mouseEnterDelay={1}
          key={Math.random()}
        >
          <Button
            key={Math.random()}
            className="vacancies__button"
            type={detail.id === props.paramId ? "primary" : "default"}
            onClick={async () => {
              await changeParamHandler(detail.id);
            }}
          >
            {detail.name.substr(0, 40)}
          </Button>
        </Popover>
      );
    });

    return (
      <Collapse accordion defaultActiveKey={acc_active_key} key={Math.random()}>
        <Panel header={param.name} key={param.id}>
          {buttons}
        </Panel>
      </Collapse>
    );
  });

  return (
    <div className="sidebar sidebar_left">
      <h4 className={"ant-typography"}>
        Показатели по региону{" "}
        <BarsOutlined
          className="sidebar__filter-icon"
          onClick={() => setIsButtonsShowed(!isButtonsShowed)}
        />
      </h4>
      {isButtonsShowed && param_buttons}
      <Button
        className="vacancies__button"
        type={"default"}
        onClick={() => {
          showModalHandler();
        }}
      >
        Показатели по РФ
      </Button>
      <RFModal visible={modalVisible} onClose={onModalClose} />
    </div>
  );
}

const mapStateToProps = (store: AppState) => {
  return {
    paramData: store.general.paramData,
    paramId: store.general.paramId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setParamId: (id: number) => dispatch(actions.general.setParamId(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Leftsidebar);
